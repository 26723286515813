import * as React from "react";
import {connect} from "react-redux";
import {navigate} from "gatsby";

// components
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";
import {StaticImage} from "gatsby-plugin-image";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const VoyagePage = ({isNewState, localisation}) => {

    React.useEffect(() => {
        if (isNewState) {
            navigate('/');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    setTimeout(() => {
        navigate("/code/consequences");
    }, 4000);

    return (
        <DefaultLayoutComponent>
            <div className="h-100 d-flex flex-column justify-content-center align-items-center bg-dark p-3">
                <div className="w-75">
                    <StaticImage src="../assets/images/voyage.svg" className="img-fluid" alt=""/>
                </div>
                <h5 className="text-center text-white-50">
                    {localisation != null && localisation === "rome" ? (
                        "DÉPLACEMENT VERS ROME..."
                    ) : (
                        "DÉPLACEMENT VERS LES PROVINCES..."
                    )}
                </h5>
                <FontAwesomeIcon icon={faCircleNotch} className="fa-2x fa-spin text-white-50" />
            </div>
        </DefaultLayoutComponent>
    )
};

export default connect(state => ({
    isNewState: state.global.codeList == null,
    localisation: state[state.global.type].player != null && state[state.global.type].player.localisation
}), null)(VoyagePage)
